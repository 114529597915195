import { useEffect } from 'react';

import { css, cx } from '@emotion/css';
import { Button, Theme, Typography } from '@mui/material';
import { useIsMutating } from '@tanstack/react-query';
import { TFunction } from 'i18next';
import { DateTime } from 'luxon';
import { UseFormReturn, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Form } from '@/components/form/Form';
import { FileView } from '@/components/pdf/FileView';
import { useStyles } from '@/hooks/useStyles';
import { CarePlanData, ConditionData } from '@/models/CreatePatientModel';
import { Patient } from '@/models/PatientModel';
import { Queries } from '@/queries/Queries';
import { ChipSelector } from '@/uiKit/molecules/ControlledChipChoice';
import { ControlledDateField } from '@/uiKit/molecules/ControlledDateField';
import { DropDown } from '@/uiKit/molecules/ControlledDropDown';
import { ControlledFileUpload } from '@/uiKit/molecules/ControlledFileInput';
import { ControlledTextField } from '@/uiKit/molecules/ControlledTextField';
import { GestationalFormWarning } from '@/uiKit/molecules/GestationalFormWarning';
import { TelemonitoringCriteriaPick } from '@/uiKit/molecules/TelemonitoringCriteriaPick';
import { TelemonitoringTierChip } from '@/uiKit/molecules/TelemonitoringTierChip';

const validateTelemonitoringForm = (
  data: TelemonitoringFormData,
  context: UseFormReturn<TelemonitoringFormData>,
  t: TFunction,
) => {
  const {
    description,
    descriptionAttachment,
    telemonitoringCriteria,
    duration,
  } = data.carePlan;

  const { diabetesType, insulinType, termDate } = data.condition;
  context.clearErrors();

  if (diabetesType === 'gestational') {
    if (!termDate) {
      context.setError('condition.termDate', {
        type: 'manual',
        message: t('form.required'),
      });
      return false;
    } else {
      const termDateObj = DateTime.fromISO(termDate);
      if (termDateObj < DateTime.now()) {
        context.setError('condition.termDate', {
          type: 'manual',
          message: t('form.pastDate'),
        });
        return false;
      }
    }
  }

  if (insulinType !== 'noInsulin') {
    if (!telemonitoringCriteria) {
      context.setError('carePlan.telemonitoringCriteria', {
        type: 'manual',
        message: t('form.required'),
      });
      return false;
    }

    if (!duration) {
      context.setError('carePlan.duration', {
        type: 'manual',
        message: t('form.required'),
      });
      return false;
    }

    if (!description && !descriptionAttachment) {
      context.setError('carePlan.description', {
        type: 'manual',
        message: t('form.atLeastOne'),
      });
      return;
    }
  }

  return true;
};

export type TelemonitoringFormData = {
  carePlan: CarePlanData;
  condition: ConditionData;
};

type TelemonitoringInformationProps = {
  onSubmit: (data: TelemonitoringFormData) => void;
  onBack: (data: TelemonitoringFormData) => void;
  carePlanFormData?: Partial<CarePlanData>;
  conditionFormData?: Partial<ConditionData>;
  patientInfo: Pick<Patient, 'givenName' | 'familyName'>;
  patientId?: string;
};

export const TelemonitoringInformation = ({
  onSubmit,
  onBack,
  conditionFormData,
  carePlanFormData,
  patientInfo,
  patientId = undefined,
}: TelemonitoringInformationProps) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  const { data: carePlanDirectives } =
    Queries.practitioner.useCarePlanDirectives({ patientId });

  const defaultValues = {
    carePlan: {
      telemonitoringCriteria:
        carePlanFormData?.telemonitoringCriteria || undefined,
      duration: carePlanFormData?.duration || '3',
      description: carePlanFormData?.description || undefined,
      descriptionAttachment:
        carePlanFormData?.descriptionAttachment || undefined,
    },
    condition: {
      diabetesType: conditionFormData?.diabetesType || undefined,
      insulinType: conditionFormData?.insulinType || undefined,
      termDate: conditionFormData?.termDate,
    },
  };

  const isMutating = useIsMutating();

  const context = useForm<TelemonitoringFormData>({
    defaultValues: defaultValues,
  });

  const descriptionAttachment = context.watch('carePlan.descriptionAttachment');
  const insulinType = context.watch('condition.insulinType');
  const diabetesType = context.watch('condition.diabetesType');

  useEffect(() => {
    context.resetField('condition.insulinType');
    context.resetField('carePlan.telemonitoringCriteria');
    context.resetField('condition.termDate');
  }, [diabetesType]);

  useEffect(() => {
    if (
      diabetesType === 'gestational' &&
      insulinType &&
      insulinType !== 'noInsulin'
    ) {
      context.setValue(
        'carePlan.telemonitoringCriteria',
        'gestational_insulined',
      );
    }
  }, [diabetesType, insulinType]);

  if (!carePlanDirectives) {
    return null;
  }

  const handleSubmit = (data: TelemonitoringFormData) => {
    if (validateTelemonitoringForm(data, context, t)) {
      onSubmit(data);
    }
  };

  const diabetesChoices = carePlanDirectives.allowedDiabetesTypes.map(type => ({
    displayName: t(`diabetesForms.diabetes.${type}`),
    value: type,
  }));

  const medicationSchemaChoices = carePlanDirectives.allowedInsulinScheme
    .filter(type => {
      if (diabetesType !== 'gestational' && type === 'noInsulin') {
        return false;
      }
      return true;
    })
    .map(type => ({
      displayName: t(`diabetesForms.insulin.${type}`),
      value: type,
    }));

  return (
    <div>
      <Form
        onSubmit={handleSubmit}
        id="TelemonitoringForm"
        formContext={context}
      >
        <div className={cx(styles.formRow, styles.marginTop)}>
          <ChipSelector
            label={t('diabetesForms.diabetesType')}
            fieldName="condition.diabetesType"
            choices={diabetesChoices}
          />
        </div>
        <div className={styles.formRow}>
          <ChipSelector
            label={t('diabetesForms.insulinScheme')}
            fieldName="condition.insulinType"
            choices={medicationSchemaChoices}
          />
        </div>
        {insulinType !== 'noInsulin' && (
          <>
            <div className={styles.formRow}>
              <Typography variant="subtitle">
                {t('diabetesForms.criteria')}
              </Typography>
              <div className={styles.sideBySide}>
                <TelemonitoringCriteriaPick patientId={patientId} />
                <TelemonitoringTierChip patientId={patientId} />
              </div>
            </div>
            <div className={styles.formRow}>
              <Typography variant="subtitle">
                {t('diabetesForms.prescriptionDuration')}
              </Typography>
              <DropDown
                className={styles.padding}
                fieldName="carePlan.duration"
                choices={['3', '2', '1'].map(value => ({
                  displayName: `${value} ${t('diabetesForms.months')}`,
                  value: value,
                }))}
              />
            </div>
            <div className={styles.formRow}>
              <div className={styles.marginBottom}>
                <Typography variant="subtitle">
                  {t('diabetesForms.goalsShort')}
                </Typography>
              </div>
              <div>
                {descriptionAttachment && (
                  <div className={styles.smallPadding}>
                    <FileView
                      file={descriptionAttachment}
                      onDelete={() =>
                        context.setValue(
                          'carePlan.descriptionAttachment',
                          undefined,
                        )
                      }
                    />
                  </div>
                )}
                <div className={styles.aside}>
                  <ControlledTextField name="carePlan.description" multiline />
                  <ControlledFileUpload
                    name="carePlan.descriptionAttachment"
                    fileType="description"
                    defaultValue={carePlanFormData?.descriptionAttachment}
                    patientInfo={patientInfo}
                    displayFilename={false}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {diabetesType === 'gestational' && (
          <div className={styles.formRow}>
            <div className={styles.marginBottom}>
              <Typography variant="subtitle">
                {t('diabetesForms.termDate')}
              </Typography>
            </div>
            <ControlledDateField name="condition.termDate" required />
          </div>
        )}
        {diabetesType === 'gestational' && insulinType === 'noInsulin' && (
          <div className={styles.formRow}>
            <div className={styles.margin}>
              <GestationalFormWarning />
            </div>
          </div>
        )}
        <div className={styles.footer}>
          <Button
            onClick={() => onBack(context.getValues())}
            variant="outlined"
          >
            {t('button.cancel')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isMutating > 0}
          >
            {t('button.next')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  formRow: css`
    width: 100%;
  `,
  footer: css`
    display: flex;
    justify-content: right;
    padding-top: ${theme.spacing(10)};
    padding-bottom: ${theme.spacing(10)};
    gap: ${theme.spacing(20)};
    width: 100%;
  `,
  sideBySide: css`
    display: flex;
    gap: ${theme.spacing(10)};
    padding-top: ${theme.spacing(12)};
    padding-bottom: ${theme.spacing(12)};
  `,
  aside: css`
    justify-content: space-between;
    display: flex;
    width: 100%;
    gap: ${theme.spacing(12)};
  `,
  padding: css`
    padding-top: ${theme.spacing(12)};
    padding-bottom: ${theme.spacing(12)};
  `,
  smallPadding: css`
    padding-top: ${theme.spacing(0)};
    padding-bottom: ${theme.spacing(4)};
  `,
  marginBottom: css`
    margin-bottom: ${theme.spacing(12)};
  `,
  marginTop: css`
    margin-top: ${theme.spacing(20)};
  `,
  margin: css`
    margin-top: ${theme.spacing(12)};
    margin-bottom: ${theme.spacing(12)};
  `,
});
