import { css } from '@emotion/css';
import { Button, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles';
import { RenewalSuggestion } from '@/models/CarePlanModel';

import { CarePlanRenewal } from '../patient-settings/CarePlanRenewal';

type PatientTableRenewButtonProps = {
  patientId: string;
  renewalSuggestion?: RenewalSuggestion;
  isButtonVisible: boolean;
  isModalOpen: boolean;
  openModal: (open: boolean) => void;
} & React.PropsWithChildren<{}>;

export const PatientTableRenewButton = ({
  children,
  patientId,
  renewalSuggestion,
  isButtonVisible,
  isModalOpen,
  openModal,
}: PatientTableRenewButtonProps) => {
  const { t } = useTranslation();

  const { tableButton } = useStyles(makeStyle, isButtonVisible);
  const buttonLabel =
    renewalSuggestion == 'expiring'
      ? t('prescription.renew')
      : renewalSuggestion == 'add_insulin'
        ? t('diabetesForms.addInsulin')
        : null;

  return (
    <>
      <div
        className={css({
          display: 'flex',
          flexDirection: 'row',
        })}
      >
        <Button
          size="small"
          variant="contained"
          onClick={e => {
            e.stopPropagation(); // Needed otherwise the navigation of the table will trigger
            openModal(true);
          }}
          className={tableButton}
        >
          {buttonLabel}
        </Button>
        {isButtonVisible ? null : children}
      </div>
      {isModalOpen ? (
        <CarePlanRenewal
          patientId={patientId}
          onClose={() => openModal(false)}
        />
      ) : null}
    </>
  );
};

const makeStyle = (_theme: Theme, isButtonVisible: boolean) => ({
  tableButton: css({
    width: isButtonVisible ? undefined : '0 !important',
    minWidth: '0 !important',
    paddingLeft: isButtonVisible ? undefined : '0 !important',
    paddingRight: isButtonVisible ? undefined : '0 !important',
    overflow: 'hidden',
    textWrap: 'nowrap',
  }),
});
