import { CarePlan, TelemonitoringTier } from './CarePlanModel';
import { ConditionType, MedicationSchema } from './ConditionModel';
import { ConditionData } from './CreatePatientModel';
import { TeleMonitoringCriteria } from './TelemonitoringCriteriaModel';

export type CarePlanCreationDirectives = {
  periodStart: string;
};

type TelemonitoringCriteriaMapMedication = {
  [key in MedicationSchema]: TeleMonitoringCriteria[];
};

type TelemonitoringCriteriaMap = {
  [key in ConditionType]: TelemonitoringCriteriaMapMedication;
};

type PreviousCarePlanData = Partial<Omit<CarePlan, 'prescription'>>; // The prescription cannot be reused in the new careplan
export type TeleMonitoringCriteriaMap = {
  [key in TeleMonitoringCriteria]: TelemonitoringTier; // Gives the tier for each criteria
};

export type CarePlanDirectives = {
  previousCarePlanData: PreviousCarePlanData; // Used to pre-fill the renewal form
  previousConditionData: ConditionData; // Used to pre-fill the renewal form
  carePlanCreationDataDirectives: CarePlanCreationDirectives; // Used to pass data computed by the backend needed to be known by the practitioner (periodStart for example)
  telemonitoringCriteriaMap: TelemonitoringCriteriaMap; // Gives the criteras available for each condition and medication
  telemonitoringTierMap: TeleMonitoringCriteriaMap; // For each criteria, gives the tier
  allowedInsulinScheme: MedicationSchema[]; // The medication schemas allowed for the patient
  allowedDiabetesTypes: ConditionType[]; // The diabetes types allowed for the patient
};

export const carePlanDirectivesDecoder = (
  value: unknown,
): CarePlanDirectives => {
  return value as CarePlanDirectives;
};
