import React, { useContext } from 'react';

import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import { ChatFilterContext } from '@/components/chat/ChatContext';
import { ExpiredTag } from '@/components/chat/header/ExpiredTag';
import { usePatientIdFromURL } from '@/hooks/usePatientIdFromURL';
import { useStyles } from '@/hooks/useStyles';
import { Queries } from '@/queries/Queries';
import { RadioGroup } from '@/uiKit/input/radio/RadioGroup';

export const ChatFilter: React.FC = () => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const filterContext = useContext(ChatFilterContext);
  const patientId = usePatientIdFromURL() ?? '';
  const { data: condition } = Queries.condition.useCondition(patientId);

  const filters = [
    {
      label: t('messaging.all'),
      name: 'filterOption',
      value: 'all',
      checked: true,
    },
  ];

  condition?.isBillable &&
    filters.push({
      label: t('messaging.medicalStaff'),
      name: 'filterOption',
      value: 'practitioners',
      checked: false,
    });

  const setFilterHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === 'all' || event.target.value === 'practitioners')
      filterContext.setFilter?.(event.target.value);
  };

  return (
    <div className={styles.container}>
      <RadioGroup options={filters} onChange={setFilterHandler} />
      <ExpiredTag />
    </div>
  );
};

const makeStyles = () => ({
  container: css`
    display: inline-flex;
  `,
});
